@keyframes pop {
    0% {
      transform: scale(0)
    }
  
    80% {
        transform: scale(1.05)
    }
    100% {
        transform: scale(1)
    }
  }
  .qr2 {
      padding: 10px;
      background: white;
      border-radius: 12px;
  }
.explainerContainerWrapper {
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: 500;
    background: rgba(0,0,0,0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    &.unclickable {
        pointer-events: none;
    }
    &.bottom {
        align-items: flex-end;
        .explainerContainer {
            margin-bottom: 25px;
        }
    }
    &.transparent {
        background: transparent;

        .explainerContainer {
            background: rgba(0,0,0,0.8);
            .topContainer {
                background: rgba(66, 66, 66, 0.8);

            }
        }
    }
    .explainerContainer {
        position: relative;
        animation: pop 0.6s normal forwards ease-in-out;
        border-radius:30px;
        z-index: 100;
        display: flex;
        flex-direction: column;
        width: 600px;
        align-items: center;
        justify-items: center;
        padding-bottom: 60px;
        background: #272727;
        padding: 2vh;
        .closeIcon {
            cursor: pointer;
            height: 35px;
            width: 35px;
            padding: 15px;
            position: absolute;
            right: -9px;
            top: -9px;
            border-radius: 50%;
            &:hover {
                background-color:rgba(0,0,0,0.2);
            }
        }
        .topContainer {
            display: flex;
            padding-top: 15px;
            padding: 12px 18px;
            background: rgb(58, 58, 58);
            border-radius: 15px;
            align-items: center;
            .gif {
                width: 150px;
                border-radius: 14px;
                border: 3px solid black;
                border-right:  8px solid black;
            }
            .explainerWhy {
                color: white;
                font-size: 17px;
                margin: 0px 5px;
            }

        }
        .bottomContainer {
            display: flex;
            flex-direction: row;
            width: 600px;
            align-items: center;
            justify-items: center;
            background: transparent;
            padding: 2vh;
            padding-bottom: 0px;
            padding-top: 2vh;
            .icon {
                width: 28px;
                margin: 0px 5px;
                margin-top: 5px;
                margin-bottom: -7px;
                display: inline-block;
            }

            .explainerImg {
                width: 60%;
                max-width: 700px;
            
            }
            .explainer {
                display: inline-block;
                text-align: left;
                color: white;
                font-size: 17px;
                margin-top: 0.5vh;
                
            }
            .qr {
                display: inline-block;
                text-align: center;
                color: white;
                font-size: 24px;
            }
            .otherExplainer{
                width: 500px;
            }
            .or {
                font-weight: bold;
                display: inline-block;
                text-align: center;
                color: white;
                font-size: 24px;
                margin-left: 25px;
                margin-right: 25px;
            }
            .link {
                margin-left: 10px;
                display: inline-block;
                text-align: left;
                text-decoration: underline;
                margin-top: 7px;
                font-size: 17px;
                color: #56c7d8;
            }
    
            .roomNumber {
                margin-top: 10px;
                margin-bottom: 5px;
                background:  #1d1d1d;
                border-radius: 35px;
                color: white;
                font-size: 60px;
                animation-name: popText;
                animation-duration: 2s;
                animation-delay: 0s;
                animation-iteration-count: infinite;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }


    }
}