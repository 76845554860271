
.logoImg {
    width: 90px;
    margin: 0px auto;
}
.testInter {
    display: block;
    position: absolute;
    z-index: 1000;
    right: 0;
    top: 50%;
    width: 255px;
    height: 655px;
    transform: translateY(-50%);
}
.testInter2 {
    display: block;
    position: absolute;
    z-index: 1000;
    left: 0;
    top: 50%;
    width: 255px;
    height: 655px;
    transform: translateY(-50%);
}
.modal {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    bottom: 10px;
    height: 100px;
    background: white;
    padding: 25px;
    border-radius: 12px;
    z-index: 10;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(255, 255, 255, 0)),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow);
    .btnExplain {
        font-size: 14px;
        color: red;
        font-weight: bold;
        margin-top: 10px;
        text-align: center;
    }
    .title {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 10px;
    }
    .description {
      font-size: 14px;
      margin: 12px 0px;
    }
    .btn {
      cursor:pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      color: #272727;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 20px;
      font-size: 16px;
      padding: 10px 20px;
      font-weight: bold;
      border: none;
      outline: none;
      border-radius: 9999px;
      background-image: linear-gradient(to right,var(--tw-gradient-stops));
      --tw-gradient-from: #42c3d3;
      --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, hsla(154, 94%, 40%, 0));
      --tw-gradient-to: #6eccdf;
      --tw-text-opacity: 1;
      --tw-shadow: 10px 10px 20px rgba(0, 0, 0, .25);
      --tw-shadow-colored: 10px 10px 20px var(--tw-shadow-color);
    }
  }
.nOfPlayersWarning {
    background-color: rgb(56, 56, 56);
    padding: 25px;
    padding-bottom: 0px;
    border-radius: 15px;
    position: absolute;
    z-index: 10000;
    border-top: 4px solid white;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    flex-direction: column;
    .warningTitle {
        color: red;
        margin-top: 0px;
        text-align: center;
        margin-bottom: 17px;
        font-weight: bold;
        font-size: 18px;
    }
    .warningDescription {
        color : white;
        white-space: pre-line;
        font-size: 16px;
        margin-bottom: -10px;

    }
    .green {
        color: greenyellow;
    }
    .red {
        color: red;
    }
}
.notWorkingExplainer {
    background-color: rgb(56, 56, 56);
    padding: 25px;
    border-radius: 15px;
    position: absolute;
    top: 255px;
    border-top: 4px solid white;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    flex-direction: column;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #ffffff00),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow);
    .chromeImg {
        width: 30px;
        margin-left: 10px;
    }
    .loaderSlowExplainerFirst {
        display: flex;
        align-items: center;
        font-size: 15px;
        margin-bottom: 4px;
        text-align: left;
        font-weight: bold;
        color: white;
    }
    .loaderSlowExplainerTitle {
        font-weight: bold;
        text-align: center;
        color: #5ce1f8;
        margin-bottom: 10px;
        font-size: 22px;
    }
    .loaderSlowExplainerTitle2 {
        font-weight: bold;
        text-align: center;
        color: #5ce1f8;
        margin-bottom: 10px;
        margin-top: 15px;
        font-size: 22px;
    }
    .loaderSlowExplainer {
        width: 100%;
        strong {
            margin-right: 4px;
            font-size: 16px;
        }
        display: flex;
        align-items: center;
        font-size: 15px;
        margin-bottom: 4px;
        text-align: left;
        color: white;
    }
    .loaderSlowExplainerLastly {
        margin-top: 10px;
        font-size: 14px;
        font-weight: bold;
        text-align: center;
        color: white;
    }
}
.wrapper {
    height: 100%;
    width: 100%;
    box-sizing:  border-box;
    font-size: 30px;
    padding-top: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex: 1;
    background-image: linear-gradient(to bottom,rgb(39, 39, 39), rgb(29, 29, 29));
    &.fullscreen{
        padding-top: 0 ;
    }
    .mainWrapper {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .gameWrapper {
            position: absolute;
            width: 100%;
            height: 100%;
        }

        .logoWrapper {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        
        .loaderWrapper {
            width: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            .loaderWrapper {
                width: 40%;
                height: 10px;


                .loader {
                    display: flex;
                    border-radius: 10px;
                    flex: 1;
                    height: 100%;
                    width: 100%;
                    border: 1px solid white;
                    .bar {
                        background-color:rgb(35, 203, 233);
                        display: flex;
                        height: 100%;
                    }
                    .emptyPart {
                        background-color:transparent;
                        display: flex;
                        height: 100%;
                    }
        
                }
            }
            .explainer {
                color: white;
                font-size: 15px;
                margin-top: 5px;
            }

            .tipWrapper{ 
                margin-top: 15px;
                font-size: 15px;
                display: block;
                                .tip {
                    display:inline-block;
                    text-align: center;
                    color: #5ce1f8;
                    font-size: 15px;
                    font-weight: bold;
                    margin-right: 5px;
                }

            }
            .loaderImg {
                width: 100%;
            }
        }
    }



}