.wrapper {
    min-height: 100%;
    width: 100%;
    box-sizing:  border-box;
    font-size: 10px;
    flex: 1;
    background-image: linear-gradient(to bottom,rgb(39, 39, 39), rgb(29, 29, 29));

}
.gamesData {
    background-color: #88ffc6;
    border-radius: 10px;
    padding: 10px;
    font-size: 12px;
    margin: 5px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    &.didntStart {
        background-color: #f5a63f;
    }
    &.inprogress {
        background-color: #88ffc6;
    }
    &.ended {
        background-color: #fd9595;
    }
    .gameName {
        font-size: 15px;
        color: #3a3a3a;
        font-weight: bold;
    }
    .gameTime {
        width: 80px;
        color:rgb(29, 29, 29);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

    }
}
.lobbyContainer {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .lobbyData {
        background: rgba(255,255,255,0.2);
        padding: 10px;
        width: 225px;
        margin: 10px;
        &.eu {
            background: rgba(20, 104, 230, 0.2)
        }
        &.us {
            background: rgba(216, 19, 19, 0.2)
        }
        .lobbyRow {
            flex-wrap: wrap;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            margin-bottom: 10px;
            &:last-child {
                margin-bottom: 0;
            }
            .descriptionLobby {
                font-size: 15px;
                color: white;
    
    
            }
            .statLobby {
                font-size: 15px;
                font-weight: bold;
                color:#90f0ff;
            }
        }
    }
}
.online {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: rgb(52, 245, 116);
}
.offline {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: red;
}
.panelStatRegional {
    display: inline-block;
    padding: 15px;
    border-radius: 20px;
    background: rgba(58, 58, 58, 1);
    color: white;
    margin-left: 20px;
    &:last-child {
        margin-right: 20px;
    }
    .stat {
        font-size: 24px;
        color: white;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px 10px;
        color: #42c3d3;
        .explainer {
            font-weight: normal;
            font-size: 14px;
            color: white;
        }
        &.eu {
            background: rgba(20, 104, 230, 0.2)
        }
        &.us {
            background: rgba(216, 19, 19, 0.2)
        }
    }
}
.panel {
    display: inline-block;
    padding: 25px;
    border-radius: 20px;
    background: rgba(58, 58, 58, 1);
    color: white;
    margin: 20px;
}
.description {
    font-size: 16px;
    margin-bottom: 15px;
    
}
.panelRow {
    display: flex;
    padding-top: 20px;
}
.stat {
    font-size: 24px;
    color: #42c3d3;
}