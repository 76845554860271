
  @keyframes popText {
    0% {
      transform: scale(1)
    }
  
    50% {
        transform: scale(1.1)
    }
    100% {
        transform: scale(1)
    }
  }
  
  @keyframes changeBorder {
    0% {
        outline: 3px solid #6eccdf;;
    }
    50% {
        outline: 10px solid #6eccdfd2;
    }
    100% {
        outline: 3px solid #6eccdf;;
    }
  }
  @keyframes goUp {
    0% {
      y: 50%;
      opacity: 0;
      transform: translate(-50%, -50%)
    }
    45% {
        y: 50%;
        opacity: 1;
        transform: translate(-50%, -50%)
        
      }
    75% {
  //      background: rgba(39, 39, 39, 0.9);
  //      box-shadow: 1px 0px 59px 0px rgba(0,0,0,0.9);
   //     -webkit-box-shadow: 1px 0px 59px 0px rgba(39, 39, 39,0.9);
 //       -moz-box-shadow: 1px 0px 59px 0px rgba(39, 39, 39,0.9);
    }
    80% {

        transform: translate(-50%, -50%);
    }
    100% {
    //    background:rgba(29, 29, 29, 0);
    //    box-shadow: 1px 0px 59px 0px rgba(29, 29, 29, 0);
     //   -webkit-box-shadow: 1px 0px 59px 0px rgba(29, 29, 29,0);
    //    -moz-box-shadow: 1px 0px 59px 0px rgba(29, 29, 29,0);
        transform: translate(-50%, -100vh);
    }
  }
  .gameRecommandation {
    position: fixed;
    z-index: 555;
    padding: 15px;
    top: -200px;
    left: 50%;
    transform: translateX(-50%);
    background: white;
    border-radius: 12px;
    width: 310px;

    box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.40);
    -webkit-box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.40);
    -moz-box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.40);
    transition: all 0.4s ease-in-out;
    &.shown {
        top: 20px;
    }
    .recommndationTitle {
        color: rgb(97, 97, 97);
        font-weight: bold;
        font-size: 14px;
    }
    .other {
        font-size: 12px;
        color: rgb(97, 97, 97);
        .availableFor {
            margin-top: 5px;
            font-size: 12px;
            color: rgb(97, 97, 97);
        }
        .platformRecommndation {
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 1;
            margin-top: 10px;
            .platformIcon {
                height: 50px;
                width: 50px;
                border-radius: 5px;
                box-shadow: #272727;
                margin-right: 10px;
                box-shadow: 0px 3px 3px 0px rgba(0,0,0,0.25);
                -webkit-box-shadow: 0px 3px 3px 0px rgba(0,0,0,0.25);
                -moz-box-shadow: 0px 3px 3px 0px rgba(0,0,0,0.25);
            }
            .appName {
                height: 50px;
                flex: 1;
                font-size: 14px;
                color: rgb(97, 97, 97);
            }
            .googlePlayIcon2 {
                margin-left: 10px;
                height: 40px;
                width: 40px;
            }
        }
    }
}
.logoWrapper {
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.block {
    display: block;
}

video {
    height: 60vh;
}
.gamesWrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    .qr {
        z-index: 20;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-60%);
        border-radius: 20px 0px 0px 20px;
        background: white;
        box-shadow: 1px 0px 59px 0px rgba(0,0,0,0.75);
        -webkit-box-shadow: 1px 0px 59px 0px rgba(0,0,0,0.75);
        -moz-box-shadow: 1px 0px 59px 0px rgba(0,0,0,0.75);
        padding: 10px;
    }
    .gamesContainer {
        position:relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        .bottomGradient {

        }
        .selectedGameDisplay {
            position: relative;;
            display: flex;
            justify-content: space-between;
            width: 100%;


    
            .gameDisplay {
                position: relative;
                video {
                    height: 50vh !important;
                    width: auto !important;
                }
                .gradient {
                    width: 60%;
                    background: rgb(39, 39, 39);
                    background: linear-gradient(90deg, rgb(39, 39, 39) 0%, rgba(0,212,255,0) 100%);
                    position: absolute;
                    left: -1px;
                    top: -2px;
                    height: 100%;
                }
                .img {
                    width: 100%;
                    height: 100%;
                }
            }
            .selectedGameData {
                z-index: 5;
                width: 30%;
                display: flex;
                padding: 40px;
                flex-direction: column;
                justify-content: space-between;
                .details {
    
                    .gameName {
                        font-size: 33px;
                        font-weight: bold;
                        color: white;
                    }
                    .participants {
                        margin-top: 7px;
                        font-size: 15px;
                        color: white;
                        display: flex;
                        align-items: center;
                        .personIcon {
                            margin-right: 4px;
                            width: 20px;
                        }
                        .gyroIcon {
                            margin-right: 4px;
                            margin-left: -7px;
                            width: 35px;
                        }
                    }
                    .description {
                        margin-top: 25px;
                        color: grey;
                        font-size: 20px;
                    }
                }
    
            }
        }
    }
}

.categoriesWrapper {
    position:relative;
    height: calc(100vh - 50vh - 60px);
    overflow-y:auto;

    .categoryLine {
        width: 100vw;
        padding-top: 0;
        padding-bottom: 20px;
        box-sizing: border-box;
        
        &:last-child {
            margin-bottom: 60px;
        }
        .categoryName {
            margin-left: 40px;
            color: white;
            font-size: 20px;
            margin-bottom: 10px;
        }
        .gamesLineWrapper {
            width: 100%;
            display: inline-block;
            .gamesLine {
                white-space: nowrap;
                position: relative;
                overflow-x: scroll;
                overflow-y: hidden;
                -webkit-overflow-scrolling: touch;
                width: 100%;
                box-sizing: border-box;
                &::-webkit-scrollbar {
                    display: none;
                }
                .game{
                    display: inline-block;
                    padding: 2vh;
                    height: 15vh;
                    width: calc(15vh * 1.711);
                    position: relative;
                    .ribbon {
                        position: absolute;
                        background-image: url("./../../assets//images//ribbon.png");
                        height: 70px;
                        width: 70px;
                        background-size:  100% 100%;
                        top: 1.5vh;
                        right:  1.5vh;
                        z-index: 10;
                        width: 100;
                        display: flex;
                        justify-content: center;
                        transition: all 0.1s ease-in-out;

                        .text {
                            margin-top: 6px;
                            margin-right: 2px;
                            color: white;
                            font-weight: bold;
                            transform: rotate(45deg);
                            margin-bottom: 10px;
                            font-size: 12px;
                            -webkit-text-stroke: #272727 2px solid;
                        }
                    }
                    &:first-child {
                        padding-left: 4vh;
                    }
                    &:last-child {
                   //     padding-right: 4vh;
                    }
                    &.selected {
                        .ribbon {
                            top: 0.9vh;
                            right: -0.3vh;
                            transform: scale(1.3);
                        }
                        .img {
  
                            box-shadow: 0px 4px #56c7d8;
                            transform: scale(1.2);
                          }
                    }
                    .img {
                        overflow: hidden;
                        transform: scale(1);

                        transition: all 0.1s ease-in-out;
                        box-shadow: 0px 0px #56c7d8;
                        border-radius: 15px;

                      width: 100%;
                      height: 100%;
                    }
                }
            }
        }
    

    }
    
}

.wrapper {
    height: 100%;
    width: 100%;
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    background: rgb(39, 39, 39);
    .connectedPlayerContainer {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1000;
        border-radius: 30px;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content:center;
        opacity: 0;
        &.shown {
            opacity: 1;
            animation: goUp 2s linear forwards;;
        }
        .circle {
            background:rgba(27, 27, 27, 0.7); 
            box-shadow: 1px 0px 59px 0px rgba(0, 0, 0, 0.9);
            -webkit-box-shadow: 1px 0px 59px 0px rgba(0, 0, 0, 0.9);
            -moz-box-shadow: 1px 0px 59px 0px rgba(0, 0, 0, 0.9);
            font-size: 14px;
            border-radius: 50%;
            height: 11vh;
            width: 11vh;
            color: white;
            outline: 3px solid #6eccdf;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 5px;
            font-size: 32px;
            color: white;
            padding: 5px;
            animation: changeBorder 2s linear infinite;
        }
        .explain {
            background:rgba(26, 26, 26, 0.8); 
            box-shadow: 1px 0px 10px 0px rgba(0, 0, 0, 0.8); 
            -webkit-box-shadow: 1px 0px 10px 0px rgba(0, 0, 0, 0.8); 
            -moz-box-shadow: 1px 0px 10px 0px rgba(0, 0, 0, 0.7); 
            padding : 2px 20px;
            border-radius: 10px;
            color: white;
            margin-top: 2px;
            font-size: 16px;
        }
    }
     .container {
        padding-top: 61px;
        top:0;
         position: fixed;
        display: flex;
        width: 100%;
        flex-direction: column;
        height: 100vh;
        overflow:auto;
        &::-webkit-scrollbar {
            display:none;
        }

        .btnWrapper {
            .startBtn {
                width: 230px;
                height: 60px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                color: #272727;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 20px;
                font-size: 20px;
                font-weight: bold;
                border: none;
                outline: none;
                border-radius: 9999px;
                background-image: linear-gradient(to right,var(--tw-gradient-stops));
                --tw-gradient-from: #42c3d3;
                --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, hsla(154, 94%, 40%, 0));
                --tw-gradient-to: #6eccdf;
                --tw-text-opacity: 1;
                --tw-shadow: 10px 10px 20px rgba(0, 0, 0, .25);
                --tw-shadow-colored: 10px 10px 20px var(--tw-shadow-color);
                box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow);
                .howTo {
                    font-weight: normal;
                    font-size: 10px;
                    color: white;
                    margin-top: 0px;
                }
            }
        }

     }

}