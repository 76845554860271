
@keyframes showControllers {
    0% {
      transform: translateY(100%) ;
    }
    100% {
        transform: translateY(0) ;
    }
  }
  @keyframes iconAnimation {
    0% {
        opacity:0;


    }
    20% {
        opacity:0;

      }
    35% {
        opacity:1;


    }
    50% {
        opacity:1;


    }
    65% {
        opacity:0;


    }
    100% {
        opacity:0;


    }
  }
  .credits {
    margin-top: 60px;
    font-size: 24px;
    color: white;
}
@keyframes buttonAnimation {
    0% {
      transform: scale(1);
      background: #42c3d3;
      box-shadow: 0 7px #238491;
      color: #054347;
      padding-right: 2.6rem;

    }
    10% {
        transform: scale(1);
        background: #42c3d3;
        box-shadow: 0 7px #238491;
        color: #03363a;
        padding-right: 2.6rem;

      }
    50% {
        transform: scale(1.08);
        background: #63c7d4;
        box-shadow: 0 7px #3da5b3;
        color: #0a4f53;
        padding-right: 4rem;


    }
    90% {
        transform: scale(1);
        background: #42c3d3;
        box-shadow: 0 7px #238491;
        color: #054347;
        padding-right: 2.6rem;

    }
    100% {
        transform: scale(1);
        background: #42c3d3;
        box-shadow: 0 7px #238491;
        color: #054347;
        padding-right: 2.6rem;

    }
  }
  @keyframes appStartBtnAnimation {
    0% {
      transform: scale(1);
      background: #42c3d3;
      box-shadow: 0 7px #238491;
      color: #054347;

    }
    10% {
        transform: scale(1);
        background: #42c3d3;
        box-shadow: 0 7px #238491;
        color: #03363a;

      }
    50% {
        transform: scale(1.08);
        background: #63c7d4;
        box-shadow: 0 7px #3da5b3;
        color: #0a4f53;


    }
    90% {
        transform: scale(1);
        background: #42c3d3;
        box-shadow: 0 7px #238491;
        color: #054347;

    }
    100% {
        transform: scale(1);
        background: #42c3d3;
        box-shadow: 0 7px #238491;
        color: #054347;

    }
  }

.unityWrapper {
    display: none;
}
.explainerContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    padding-bottom: 60px;
    margin: auto 0;
    .supportTop {
        position: absolute;
        z-index: 1000;
        top: 0;
        left: 50%;
        transform: translate(-50%);
        color: white;
        text-decoration: none;
        background: #3a3a3a;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px 20px;
        border-radius: 0px 0px 20px 20px;
        .textContainer {
            .question {
                font-size: 12px;
                color: white;
            }
            .answer {
                font-size: 16px;
                font-weight: bold;
                color: white;
            }
        }
        .icon {
            width: 30px;
            margin-right: 15px;
        }

    }
    .startBtn {
        
        transform: scale(1);
        animation-name: appStartBtnAnimation;
        animation-duration: 1.5s;
        animation-delay: 0s;
        animation-iteration-count: infinite;
        margin-top: 4vh;
        margin-bottom: 4vh;
        width: 100%;
        display: flex;
        color: #272727;
        padding: 15px 30px;
        max-width: 350px;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        font-size: 24px;
        border: none;
        outline: none;
        cursor: pointer;
        border-radius: 9999px;
        background-image: linear-gradient(to right,var(--tw-gradient-stops));
        --tw-gradient-from: #42c3d3;
        --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, hsla(154, 94%, 40%, 0));
        --tw-gradient-to: #6eccdf;
        --tw-text-opacity: 1;
        --tw-shadow: 10px 10px 20px rgba(0, 0, 0, .25);
        --tw-shadow-colored: 10px 10px 20px var(--tw-shadow-color);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow);
    }
    .gameDisplay {
        background: rgba(58, 58, 58, 1);
        padding-bottom: 0;
        border-radius: 40px;
        margin-top: 60px;
        padding: 4vh;
        position: relative;
        display: flex;
        overflow:hidden;
        justify-content: center;
        .explainerImg {
            height: 20vh;
            position: absolute;
            bottom: 0px;
            transform:translateY(100%);
            animation-name: showControllers;
            animation-duration: 1s;
            animation-delay: 0.2s;
            animation-fill-mode: forwards;  

        }
        .playerWrapper {
            margin-bottom: 30px;
            border:2px solid #1D1D1D;
            background: #1D1D1D;
            video {
                height: 35vh !important;
                width: auto !important;
            }
        }
    }
    .explainer {
        font-weight: bold;
        text-align: center;
        color: #56c7d8;
        font-size: 40px;
        margin-top: 20px;
        margin-bottom: 60px;
        font-family: OpenSansBold;
        
    }
    .explainer2 {
        max-width: 900px;
        margin-top: 2vh;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: white;
        font-size: 22px;
    }
    .link {
        text-decoration: underline;
        font-size: 18px;
        margin-left: 10px;
        color: #56c7d8;
    }
}
.parent {
    margin-bottom: 60px;
}
.browserExplainerContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    padding-bottom: 60px;
    margin: auto 0;
    width: 100%;

    .consoleExplainer {
        margin-top: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .blockLink {
            display: inline-block;
            text-decoration: underline;
        }
        &:first-child {
            margin-top: 60px;

        }
        .howDoes {
            font-weight: bold;
            color: #42c3d3;
            margin-bottom: 30px;
        }
        .answer {
            width: 350px;
            text-align: center;
            display: inline-block;
            color: white;
            font-size: 18px;
        }
    }

    .gameDisplayMobile {
        width: 95%;
        height: auto;
        background: rgba(58, 58, 58, 1);
        padding-bottom: 0;
        max-width: 800px;
        border-radius: 40px;
        margin-top: 60px;
        padding: 30px;
        position: relative;
        display: flex;
        box-sizing: border-box;
        justify-content: center;
        overflow:hidden;
        .explainerImgMobile  {
            width: 120%;
            position: absolute;
            bottom: 0px;
            left: 50%;
            transform:translateX(-50%);

        }
        .playerWrapperMobile  {
            width: 100%;
            border:2px solid #1D1D1D;
            background: #1D1D1D;
        }
    }
    .explainer {
        max-width: 350px;
        text-align: center;
        color: white;
        font-size: 20px;
        margin-top: 20px;
        margin-bottom: 30px;
        
    }
    .link {
        text-decoration: underline;
        font-size: 18px;
        margin-left: 10px;
        color: #56c7d8;
    }
    .carouselWrapper {
        margin-top: 10px;
        overflow:hidden;
        width: 100%;
    }

    .tip {
        font-size: 11px;
        color : orange;
    }
    .startBtnApp { 
        text-decoration: none;
        transform: scale(1);
        animation-name: appStartBtnAnimation;
        animation-duration: 2s;
        animation-delay: 0s;
        animation-iteration-count: infinite;
        margin-top: 15px;
        min-width: 225px;
        display: flex;
        color: #272727;
        padding: 11px 35px;
        max-width: 300px;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        font-size: 22px;
        border: none;
        outline: none;
        cursor: pointer;
        border-radius: 9999px;
        background-image: linear-gradient(to right,var(--tw-gradient-stops));
        --tw-gradient-from: #42c3d3;
        --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, hsla(154, 94%, 40%, 0));
        --tw-gradient-to: #6eccdf;
        --tw-text-opacity: 1;
        --tw-shadow: 10px 10px 20px rgba(0, 0, 0, .25);
        --tw-shadow-colored: 10px 10px 20px var(--tw-shadow-color);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow);
        .googlePlayIcon {
            margin-right: 10px;
            height: 37px;
            width: 37px;
        }
    }
    .startBtn {
        color: white;
        margin-top: 30px;
        min-width: 225px;
        display: flex;
        padding: 15px 25px;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        border-radius: 20px;
        font-size: 22px;
        border: none;
        outline: none;
        cursor: pointer;
        border-radius: 9999px;
        background-image: linear-gradient(to right,var(--tw-gradient-stops));
        border: 2px solid #42c3d3;
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow);
    }
}
.wrapper {
    min-height: 100%;
    width: 100%;
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: linear-gradient(to bottom,rgb(39, 39, 39), rgb(29, 29, 29));
    .wrapper2 {
        min-height: 100%;
        width: 100%;
        font-size: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.titleContainer {
    position: absolute;
    left: 0px;
    top: -15px;
    display: flex;
    align-items: center;
    justify-content: center;
    .logoImg {
        height: 120px;
        width: 120px;
    }
    .title {
        font-size: 30px;
        color: white;
        margin-left: -10px;
    }
}

.titleContainerMobile {
    position: absolute;
    left: -4px;
    top: -8px;
    display: flex;
    align-items: center;
    justify-content: center;
    .logoImg {
        height: 80px;
        width: 80px;
    }
    .title {
        font-size: 24px;
        color: white;
        margin-left: -5px;
    }
}
.mobileView {
    height: 100%;
    width: 100%;
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.browserView {
    height: 100%;
    width: 100%;
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.description {

    padding: 20px;
    padding-top:10px;
    color: white;
    font-size: 17px;
  }
  .link {
    text-decoration: underline;
    font-size: 14px;
    margin-left: 10px;
    margin-top: -10px;
    color: #56c7d8;
}
.indicator {
    background: white;
    transition: opacity .25s ease-in;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    cursor: pointer;
    display: inline-block;
    margin: 30px 4px 0;
    opacity: 0.5;
    &.active {
        opacity: 1;
    }
}

.playButton {
    cursor: pointer;
    display: inline-block;
    padding: 0.9rem 2.6rem;
    padding-right: 2.6rem;
    border-radius: 2.5rem;
    color: #242424;
    box-shadow: 0 7px #238491;
    font-size: 1.6rem;
    transition: all .3s;
    position: relative;
    overflow: hidden;
    z-index: 1;
    margin-top: 18px;
    animation-name: buttonAnimation;
    animation-duration: 2s;
    animation-delay: 0s;
    animation-iteration-count: infinite;
    .invertedIcon {
        width: 65px;
        right: -2px;
        position: absolute;
        opacity:0;
        top: 51%;
        transform: translateY(-50%);
        transition: all .3s;
        animation-name: iconAnimation;
        animation-duration: 2s;
        animation-delay: 0s;
        animation-iteration-count: infinite;
    }
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 10rem;
      z-index: -2;
    }
    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0%;
      height: 100%;
      background-color: lighten(#42c3d3, 15%);
      transition: all .3s;
      border-radius: 10rem;
      z-index: -1;
    }
    &:hover {
        color: #242424;
        padding-right: 4rem;

        .invertedIcon {
            opacity:1;
        }
        &:before {
        width: 100%;
      }
    }
  }