.wrapper {
    min-height: 100%;
    width: 100%;
    box-sizing: border-box;
    padding-top: 60px;
    flex: 1 1;
    background-image: linear-gradient(to bottom, #272727, #1d1d1d);
    display: flex;
    flex-direction: column;
    padding:15px;
    .number {
        color: #56c7d8;
        font-size: 18px;
        padding-right: 10px;
        box-sizing: border-box;
        min-width: 40px;
        max-width: 40px;
        display: flex;
        justify-content: flex-end;
    }
    .log {
        font-size: 16px;
        color: white;
        display: flex;
    }
}