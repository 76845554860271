
  @keyframes popText {
    0% {
      transform: scale(1)
    }
  
    50% {
        transform: scale(1.1)
    }
    100% {
        transform: scale(1)
    }
  }
  .wrapperMobile {
    color: white;
    margin-top: 20px;
    padding: 40px;
    min-height: 500px;
  }
  .startBtn {
    z-index: 10;
    
    font-family: OpenSans;
    position: absolute;
    transform: scale(1);
    animation-name: buttonAnimation;
    animation-duration: 2s;
    animation-delay: 0s;
    animation-iteration-count: infinite;
    width: 100%;
    display: flex;
    color: #272727;
    max-width: 300px;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    font-size: 18px;
    padding: 10px 0px;
    border: none;
    outline: none;
    cursor: pointer;
    text-decoration: none;
    border-radius: 9999px;
    background-image: linear-gradient(to right,var(--tw-gradient-stops));
    --tw-gradient-from: #42c3d3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, hsla(154, 94%, 40%, 0));
    --tw-gradient-to: #6eccdf;
    --tw-text-opacity: 1;
    --tw-shadow: 10px 10px 20px rgba(0, 0, 0, .25);
    --tw-shadow-colored: 10px 10px 20px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow);
}

.logoWrapper {
    z-index: 100;
    position: fixed;
    left: 20px;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .text {
        color: white;
        font-size: 25px;
        font-weight: bold;
        margin-left: -2px;
    }
    .logoImg {
        width: 88px;
        
    }
}
.logoWrapperLoading {
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.block {
    display: block;
}

video {
    height: 60vh;
}
.gamesWrapper {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    .qr {
        z-index: 20;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 20px 0px 0px 20px;
        background: white;
        padding: 10px;
    }
    .gamesContainer {
        position:relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        .bottomGradient {

        }
        .selectedGameDisplay {
            height: 100%;
            position: relative;;
            display: flex;
            justify-content: space-between;
            width: 100%;


    
            .gameDisplay {
                position: relative;
                position: absolute;
                top: 0;
                video {
                    width: 100vw !important;
   
                }
                
                .gradient {
                    width: 100vw;
                    height: 20vh;
                    background: rgb(39, 39, 39);
                    background: linear-gradient(0, rgb(39, 39, 39) 15%, rgba(0,212,255,0) 100%);
                    position: absolute;
                    left: 0px;
                    bottom: 0px;
                }
                .img {
                    width: 100%;
                    height: 100%;
                }
            }
            .selectedGameData {
                position: absolute;
                z-index: 5;
                width: 30%;
                display: flex;
                height: 100%;
                padding: 40px;
                flex-direction: column;
                justify-content: space-between;
                background: linear-gradient(90deg, rgba(39, 39, 39, 1) 0%, rgba(0,212,255,0) 100%);
                &.mobile {
                    width: auto;
                }
    
                .details {
                    margin-top: 10vh;
                    .gameName {
                        font-size: 35px;
                        font-weight: bold;
                        color: white;
                        .gameImage {
                            transform: translateY(25px);
                            width: 120px;
                            margin-left: 10px;
                            border-radius: 15px;
                            display: inline-block;
                        }
                    }
                    .participants {
                        margin-top: 7px;
                        font-size: 17px;
                        color: white;
                        display: flex;
                        align-items: center;
                        .personIcon {
                            margin-right: 4px;
                            width: 25px;
                        }
                    }
                    .description {
                        margin-top: 20px;
                        max-height: 60vh;
                        overflow: auto;
                        color: white;
                        font-size: 19px;
                        &::-webkit-scrollbar {
                            width: 10px;
                          }
                          
                          /* Track */
                          &::-webkit-scrollbar-track {
                            background: #888;
                          }
                          
                          /* Handle */
                          &::-webkit-scrollbar-thumb {
                            background: #f1f1f1;

                          }
                          
                          /* Handle on hover */
                          &::-webkit-scrollbar-thumb:hover {
                            background: #555;
                          }
                    }
                }
    
            }
        }
    }
}

.categoriesWrapper {
    position:relative;
    height: calc(100vh - 50vh - 60px);
    overflow-y:auto;

    .categoryLine {
        width: 100vw;
        padding-top: 0;
        padding-bottom: 20px;
        box-sizing: border-box;
        
        &:last-child {
            margin-bottom: 60px;
        }
        .categoryName {
            margin-left: 40px;
            color: white;
            font-size: 20px;
            margin-bottom: 10px;
        }
        .gamesLineWrapper {
            width: 100%;
            display: inline-block;
            .gamesLine {
                white-space: nowrap;
                position: relative;
                overflow-x: scroll;
                overflow-y: hidden;
                -webkit-overflow-scrolling: touch;
                width: 100%;
                box-sizing: border-box;
                &::-webkit-scrollbar {
                    display: none;
                }
                .game{
                    display: inline-block;
                    padding: 2vh;
                    height: 15vh;
                    width: calc(15vh * 1.711);
                    position: relative;
                    .ribbon {
                        position: absolute;
                        background-image: url("./../../assets//images//ribbon.png");
                        height: 70px;
                        width: 70px;
                        background-size:  100% 100%;
                        top: 1.5vh;
                        right:  1.5vh;
                        z-index: 10;
                        width: 100;
                        display: flex;
                        justify-content: center;
                        transition: all 0.1s ease-in-out;

                        .text {
                            margin-top: 6px;
                            margin-right: 2px;
                            color: white;
                            font-weight: bold;
                            transform: rotate(45deg);
                            margin-bottom: 10px;
                            font-size: 12px;
                            -webkit-text-stroke: #272727 2px solid;
                        }
                    }
                    &:first-child {
                        padding-left: 4vh;
                    }
                    &:last-child {
                   //     padding-right: 4vh;
                    }
                    &.selected {
                        .ribbon {
                            top: 0.9vh;
                            right: -0.3vh;
                            transform: scale(1.3);
                        }
                        .img {
  
                            box-shadow: 0px 4px #56c7d8;
                            transform: scale(1.2);
                          }
                    }
                    .img {
                        overflow: hidden;
                        transform: scale(1);

                        transition: all 0.1s ease-in-out;
                        box-shadow: 0px 0px #56c7d8;
                        border-radius: 15px;

                      width: 100%;
                      height: 100%;
                    }
                }
            }
        }
    

    }
    
}

.wrapper {
    height: 100%;
    width: 100%;
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    background: rgb(39, 39, 39);
     .container {
        top:0;
         position: fixed;
        display: flex;
        width: 100%;
        flex-direction: column;
        height: 100vh;
        overflow:auto;
        &::-webkit-scrollbar {
            display:none;
        }


     }

}