@keyframes pop {
  0% {
    transform: scale(0)
  }

  80% {
      transform: scale(1.05)
  }
  100% {
      transform: scale(1)
  }
}
.pingContainer {
  color: white;
  font-size: 13px;
  position: absolute;
  left: 100px;
  top: 0;
  padding: 6px;
  padding-top: 2px;
  border-radius: 0px 0px 10px 10px ;
  &.good {
    background-color: #69bd69;
  }
  &.medium {
    background-color: rgb(221, 163, 56);
  }
  &.bad {
    background-color: rgb(245, 77, 77);
  }
  &.notPlayable {
    background-color: rgb(88, 8, 8);
  }
}
.flag {
  width: 35px;
}
.addPlayersContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: #272727;
  margin-right: 10px;
  border-radius:10px;
  padding: 0px 10px;

  .plus {
    font-weight: bold;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .addPlayers {
    margin-left: 10px;
    color: white;
    font-size: 14px;
  }
}
.languageWrapper {
  position:relative;

  .language {
    cursor: pointer;
    background: #272727;
    margin-right: 10px;
  
    border-radius:10px;
    padding: 3px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    .languageTitle {
      font-size: 14px;
     color: white; 
     margin-right: 5px;
    }

  }
  .flagMenu {
    position:absolute;
    display: block;
    z-index: 5;
    top: 45px;
    left: 50%;
    transform:translate(-50%);
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background:#464646;
    border-radius: 10px;
    padding: 5px;
    width: 255px;
    .languageRowWrapper {
      display: inline-block;
      cursor: pointer;
      .languageRow {
        background-color:rgb(53, 53, 53);
        border-radius: 5px;
        margin: 3px;
        width: 110px;
        padding: 5px;
        display: flex;
        align-items: center;
        
        .languageName {
          font-size: 13px;
          color: white;
          margin-left: 5px;
        }
        .flag {
          width: 30px;
        }
      }
    }


  }
}

.topLayer {
  height: 60px;
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 10;
  background: rgb(29, 29, 29); 
  display: flex;
  flex: 1;
  &.playMode {
    background: transparent;
    .roomData {
      background:  rgba(29, 29, 29,0.3);
      .roomNumber {
      }

    }

    .players {
      .languageWrapper {
        display: none;
      }
      .addPlayersContainer {
        background:  rgba(29, 29, 29,0.3);

      }
      .player {
        display: none;
      }
    }
    .titleContainer {
      display: none;
    }
  }
  .viewQRBig {

  }
  .messageTitleContainer {
    position: absolute;
    height: 50px; 
    left: 50%;
    top: 50%;
    transform: translate(-150%, -50%);
    display: flex;
    align-items: center;
    background: #272727;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 30px;
    justify-content: center;
    .logoImg {
      width: 50px;
  }
  .title {
      font-size: 24px;
      color: white;
      margin-left: 10px;
  }
  }
  .titleContainer {
    position: absolute;
    height: 50px; 
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    background: #272727;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 30px;
    justify-content: center;
    .logoImg {
        width: 60px;
    }
    .title {
        font-size: 24px;
        color: white;
        margin-left: 10px;
    }
}
  .enlargeIcon {
    position: absolute;
    top: 50%;
    width: 30px;
    transform: translateY(-50%);
    left: 6px;
    cursor: pointer;
    padding: 10px;
  }
  .roomData {
    
    border-bottom:  2px solid white;
    border-left:  2px solid white;
    background-image: linear-gradient(to bottom,rgb(39, 39, 39), rgb(29, 29, 29));
    
    border-bottom-left-radius: 15px;
    padding: 6px 12px;
    display: flex;
    justify-content: center;
    align-items: center;


    .roomNumber {
      font-size: 36px;
      font-weight: bold;
      letter-spacing: 2px;
      color: white;
  }
  }

  .players {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 25px;
    .player {
      background: rgb(29, 29, 29); 
        font-size: 14px;
        border-radius: 50%;
        height: 45px;
        width: 45px;
        color: white;
        border: 2px solid #6eccdf;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 5px;
        .playerText {
          text-align: center;
          width: 42px;
        }
    }
}
}