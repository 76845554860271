
.fullscreen {
  width: 100%;
  height: 100%;
}
.btn {
  cursor:pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #272727;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  font-size: 16px;
  padding: 10px 20px;
  font-weight: bold;
  border: none;
  outline: none;
  border-radius: 9999px;
  background-image: linear-gradient(to right,var(--tw-gradient-stops));
  --tw-gradient-from: #42c3d3;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, hsla(154, 94%, 40%, 0));
  --tw-gradient-to: #6eccdf;
  --tw-text-opacity: 1;
  --tw-shadow: 10px 10px 20px rgba(0, 0, 0, .25);
  --tw-shadow-colored: 10px 10px 20px var(--tw-shadow-color);
}
.flex{
  display: flex;
}
.cantStart {
  width: 100%;
  height: 100%;
  min-height: 100%;
  width: 100%;
  font-size: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(to bottom,rgb(39, 39, 39), rgb(29, 29, 29));
}

