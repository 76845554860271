@keyframes pop3 {
    0% {
      transform: scale(1) 
    }
  
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1)
    }
  }
  @keyframes showBanner {
    0% {
      transform: translateY(-100%)
    }
    85% {
        transform: translateY(-100%)
      }
    95% {
        transform: translateY(5%)
    }
    100% {
        transform: translateY(-5%)
    }
  }
.suggestAppBanner {
    width: 100vw;
    z-index: 100000;
    padding-top: 15px;
    background: #3a3a3a;
    border-radius: 0px 0px 20px 20px;
    padding-bottom: 10px;
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(5%);
    animation: showBanner 2s linear 0s;
    -webkit-box-shadow: 0px 5px 61px 11px rgba(0,0,0,1);
    -moz-box-shadow: 0px 5px 61px 11px rgba(0,0,0,1);
    box-shadow: 0px 5px 61px 11px rgba(0,0,0,1);
    transform: translateY(0%);
    text-align: center;
    .description {
        font-size: 14px;
        color: white;
        margin:0px 20px;
        
    }
    .descriptionExplain {
        font-size: 12px;
        margin-top: 3px;
        &.error {
            color: red;
        }
        &.warning {
            color: orange;
        }

    }
    .optionsContainer {
        display: flex;
        align-items: center;
        flex: 1;
        .startButton {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content:  flex-end;
    
            .icon {
                margin-right: 10px;
                height: 37px;
                width: 200px;
                height: auto;
            }
        }
        .noThanksWrapper {
            flex: 1;
            display: flex;
            padding-right: 10px;
            .noThanks {
                max-width: 150px;
                width: 100%;
                color: white;
                border-radius: 6px;
                height: 48px;
                border: 1px solid rgb(148, 148, 148);
                font-size: 20px;
                cursor: pointer;
                display: flex;
                padding:0px 10px;
                text-align: center;
                align-items: center;
                justify-content: center;
            }
        }
 
    }

}
.fullscreenContainer {
    height: 100vh;
    width: 100vw;
    z-index: 100;
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;

    .fullscreenCircle {
        margin: auto;
        animation-name: pop3;
        animation-duration: 2s;
        animation-delay: 0s;
        animation-iteration-count: infinite;
        padding: 10px;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 50%;
        width: 200px;
        height: 200px;
        justify-content: center;
        background: rgba(58, 58, 58, 0.95);
        border: 2px solid white;
        .fullscreenExplainer {
            text-align: center;
            font-size: 21px;
            color: white;
            width: 170px;
            margin-top: 15px;
        }
        .fullscreenIcon {
            height: 75px;
            width: 75px;
        }
    }
}
.landscapeWrapper  {
    transform: rotate(-90deg);
    transform-origin: left top;
    width: 100vh;
    height: 100vw;
    overflow-x: hidden;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: linear-gradient(to bottom,rgb(39, 39, 39), rgb(29, 29, 29));
    top: 100%;
    left: 0;
    .unityWrapper {
        position: absolute;
        z-index: 5;
        height: 100%;
        width: 100%;
    }
    .loaderWrapper {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .explainer {
            margin-top: 3px;
            color: #6eccdf;
            font-size: 10px;
        }
    }
  }
.wrapper {
    height: 100%;
    width: 100%;
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: linear-gradient(to bottom,rgb(39, 39, 39), rgb(29, 29, 29));
    .unityWrapper {
        position: absolute;
        z-index: 5;
        height: 100%;
        width: 100%;
    }
    .loaderWrapper {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        &.rtl {
            direction: rtl;
        }
        .refreshContainer {
            margin-top: 10px;
            color: white;
            font-size : 13px;
            display: flex;
            flex-direction: column;
            .chrome { 
                height: 30px;
                margin-left: 10px;
            }
            .row {
                margin-top: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .refresh {

            }
            .refreshLink {
                z-index: 10000;
                margin: 0px 5px;
                cursor:pointer;
                text-decoration: underline;
            }

        }
        .explainer {
            margin-top: -25px;
            color: #6eccdf;
            font-size: 20px;
        }
    }
}
.interContainer {
    position: absolute;
    z-index: 10000;
    left: 0;
    top: 0;
    background-color: rgb(51, 51, 51);
    height: 100vh;
    width: 100vw;
    .testInter {
        display: inline-block;
        position:absolute;
        top: 50%;
        transform: translate(-50%,-50%);
        left:50%;
        z-index: 15000;
        width: 100%;
        height: 100%;
    }
    .x {
        cursor: pointer;
        position:absolute;
        top: 15px;
        right: 15px;
        z-index: 20000;
        height: 43px;
        width: 43px;
        font-size: 22px;
        display: flex;
        background: white;
        justify-content: center;
        align-items: center;
        color: black;
        border: 1px solid white;
        border-radius:  50%;
    }
}

.testBanner {
    display: inline-block;
    position:absolute;
    bottom:0;
    left:50%;
    transform:translateX(-50%);
    width: 360px;
    height: 60px;
    z-index: 1300;
}
.supportWrapper {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    z-index: 100;
    background: #3a3a3a;
    padding: 25px;
    padding-bottom: 0;
    border-radius: 25px;
    width: 70vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 2px solid #42c3d3;

    .supportButton {
        cursor:pointer;
        animation-name: pop3;
        animation-duration: 2s;
        animation-delay: 0s;
        animation-iteration-count: infinite;
        flex-direction: column;
        color: #272727;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        font-size: 16px;
        padding: 10px 20px;
        font-weight: bold;
        border: none;
        outline: none;
        border-radius: 9999px;
        background-image: linear-gradient(to right, var(--tw-gradient-stops));
        --tw-gradient-from: #42c3d3;
        --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, hsla(154, 94%, 40%, 0));
        --tw-gradient-to: #6eccdf;
        --tw-text-opacity: 1;
        --tw-shadow: 10px 10px 20px rgba(0, 0, 0, .25);
        --tw-shadow-colored: 10px 10px 20px var(--tw-shadow-color);
        margin-bottom: 15px;

    }
    .supportMessage {
        white-space: pre-line;
        color:white;
        font-size: 16px;
        font-weight: bold;
        &.marked {
            color: #42c3d3;
            margin-top: 10px;
        }
    }

    .supportLogo {
        margin-top: -10px;
        width: 90px;

    }
}
