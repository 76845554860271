.wrapper {
    box-sizing: border-box;
    flex: 1 1;
    font-size: 30px;
    min-height: 100%;
    padding-top: 60px;
    width: 100%;
    padding: 40px;
    text-align: center;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
p {
    color: white;
    font-size: 17px;
}
h1 {
    color: white;
    font-size: 24px;
}
h2 {
    color: white;
    font-size: 22px;
}
.button {
    cursor: pointer;
    margin:auto;
    width: 230px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #272727;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    font-size: 20px;
    font-weight: bold;
    border: none;
    outline: none;
    border-radius: 9999px;
    background-image: linear-gradient(to right,var(--tw-gradient-stops));
    --tw-gradient-from: #42c3d3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, hsla(154, 94%, 40%, 0));
    --tw-gradient-to: #6eccdf;
    --tw-text-opacity: 1;
    --tw-shadow: 10px 10px 20px rgba(0, 0, 0, .25);
    --tw-shadow-colored: 10px 10px 20px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow);
    margin-top: 100px;
}