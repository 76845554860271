
@keyframes buttonAnimation {
    0% {
      transform: scale(1);
    }
  
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1)
    }
  }
.free {
    margin-left: 5px;
}
.contentWrapper {
    max-width: 720px;
    padding-bottom: 250px;
}
.image {
    position :absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 0px;
    width: 90px;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;

}
.wrapper {
    background-image: linear-gradient(180deg,#272727,#1d1d1d);

.explainerContainer {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    padding-bottom: 60px;
    margin: auto 0;

    .gameDisplayMobile {
        width: 100%;
        height: 280px;
        background: rgba(58, 58, 58, 1);
        padding-bottom: 0;
        margin-top: 60px;
        padding: 20px;
        position: fixed;
        bottom: 0;
        display: flex;
        box-sizing: border-box;
        justify-content: center;
        .scrollExplainer {
            font-size: 14px;
            color: white;
            position: absolute;
            top: -15px;
            z-index: 51;

        }
        .gradient {
            height: 77px;
            width: 100%;
            background: rgb(34,193,195);
            background: linear-gradient(0deg, rgba(58, 58, 58, 1) 0%, rgba(58, 58, 58, 0) 100%);
            position: absolute;
            top: 0;
            z-index: 50;
            transform: translateY(-96%);
        }
        .bottomIllustration {
            position: absolute;
            width: 360px;
            left: 50%;
            bottom: 0;
            transform: translateX(-50%);
            .explainerImgMobile  {
                width: 444px;
                position: absolute;
                bottom: 0px;
                left: -33px;
                z-index: 2;
    
            }
            .playerWrapperMobile  {
                z-index: 1;
                width: 300px;
                position: absolute;
                left: 27px;
                border:2px solid #1D1D1D;
                background: #1D1D1D;
                bottom:25px;
            }
        }

    }

.titleContainerMobile {
    position: absolute;
    left: -4px;
    top: -8px;
    display: flex;
    align-items: center;
    justify-content: center;
    .logoImg {
        height: 70px;
        width: 70px;
    }
    .title {
        font-size: 15px;
        color: white;
        margin-left: -8px;
    }
}
    .titleContainer {
        position: absolute;
        left: 0px;
        top: -15px;
        display: flex;
        align-items: center;
        justify-content: center;
        .logoImg {
            height: 120px;
            width: 120px;
        }
        .title {
            font-size: 30px;
            color: white;
            margin-left: -10px;
        }
    }
    
    .startBtn {
        z-index: 10;
        
        font-family: OpenSans;
        position: absolute;
        transform: scale(1);
        animation-name: buttonAnimation;
        animation-duration: 2s;
        animation-delay: 0s;
        animation-iteration-count: infinite;
        width: 100%;
        display: flex;
        color: #272727;
        max-width: 300px;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        font-size: 18px;
        padding: 10px 0px;
        border: none;
        outline: none;
        cursor: pointer;
        text-decoration: none;
        border-radius: 9999px;
        background-image: linear-gradient(to right,var(--tw-gradient-stops));
        --tw-gradient-from: #42c3d3;
        --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, hsla(154, 94%, 40%, 0));
        --tw-gradient-to: #6eccdf;
        --tw-text-opacity: 1;
        --tw-shadow: 10px 10px 20px rgba(0, 0, 0, .25);
        --tw-shadow-colored: 10px 10px 20px var(--tw-shadow-color);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow);
    }
    .gameDisplay {
        width: 732px;
        height: 467px;
        background: rgba(58, 58, 58, 1);
        padding-bottom: 0;
        max-width: 800px;
        border-radius: 40px;
        margin-top: 60px;
        padding: 30px;
        position: relative;
        display: flex;
        justify-content: center;
        .explainerImg {
            width: 732px;
            position: absolute;
            bottom: 0px;

        }
        .playerWrapper {
            width: 510px;
            position: absolute;
            left:132px;
            border:2px solid #1D1D1D;
            background: #1D1D1D;
            bottom: 173px;
        }
    }
    .explainer {
        font-weight: bold;
        text-align: center;
        color: #56c7d8;
        font-size: 40px;
        margin-top: 20px;
        margin-bottom: 60px;
        font-family: OpenSansBold;
        
    }
    .explainer2 {
        max-width: 900px;
        margin-top: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: white;
        font-size: 26px;
    }
    .link {
        text-decoration: underline;
        font-size: 18px;
        margin-left: 10px;
        color: #56c7d8;
    }
}
}