
@keyframes buttonAnimation {
    0% {
      transform: scale(1)
    }
  
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1)
    }
  }
.wrapper {
    min-height: 100%;
    width: 100%;
    font-size: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: linear-gradient(to bottom,rgb(39, 39, 39), rgb(29, 29, 29));
    .gif {
        width: 50%;
        margin-top: 10px;
        border-radius: 14px;
        border: 3px solid black;
        border-right:  8px solid black;
        margin-bottom: -20px;

    }
    .logoImg2 {
        width: 50vw;
        margin-bottom: -20px;
    }
    .text {
        color: white;
        font-size: 25px;
        text-align: center;
        white-space: pre-wrap;
    }
    .topper {
        position: absolute;
        top:10px;
        left: 10px;
        display: flex;
        width: 100%;
        height: 50px;
        .logoImg {
            width: 90px;
            height: 90px;
            margin-top: -27px;
        }
    }
    .text2 {
        color: white;
        font-size: 16px;
        text-align: center;
        margin: 0 6vw;
        white-space: pre-wrap;
    }
    .dontHave {
        margin-top: 15px;
        margin-bottom: 5px;
        color: white;
        font-size: 25px;
        text-align: center;
        white-space: pre-wrap;
    }
    .startButtonInvert { 
        text-decoration: none;
        transform: scale(1);
        margin-top: 15px;
        min-width: 225px;
        display: flex;
        color: #272727;
        padding: 11px 0px;
        width: 300px;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        font-size: 22px;
        border: #42c3d3 solid 2px;
        outline: none;
        cursor: pointer;
        color: white;
        border-radius: 9999px;
background: transparent;
        --tw-gradient-from: #42c3d3;
        --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, hsla(154, 94%, 40%, 0));
        --tw-gradient-to: #6eccdf;
        --tw-text-opacity: 1;
        --tw-shadow: 10px 10px 20px rgba(0, 0, 0, .25);
        --tw-shadow-colored: 10px 10px 20px var(--tw-shadow-color);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow);
    }
    .startButton { 
        width: 250px;
        text-decoration: none;
        transform: scale(1);
        animation-name: buttonAnimation;
        animation-duration: 2s;
        animation-delay: 0s;
        animation-iteration-count: infinite;
        margin-top: 15px;
        min-width: 225px;
        display: flex;
        color: #272727;
        padding: 11px 25px;
        max-width: 300px;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        font-size: 22px;
        border: none;
        outline: none;
        cursor: pointer;
        border-radius: 9999px;
        background-image: linear-gradient(to right,var(--tw-gradient-stops));
        --tw-gradient-from: #42c3d3;
        --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, hsla(154, 94%, 40%, 0));
        --tw-gradient-to: #6eccdf;
        --tw-text-opacity: 1;
        --tw-shadow: 10px 10px 20px rgba(0, 0, 0, .25);
        --tw-shadow-colored: 10px 10px 20px var(--tw-shadow-color);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow);
        .googlePlayIcon {
            margin-right: 10px;
            height: 37px;
            width: 37px;
        }
    }
}
