.wrapper {
    min-height: 100%;
    width: 100%;
    font-size: 30px;
    display: flex;
    flex-direction: column;
    color: white;
    align-items: center;
    justify-content: center;
    background-image: linear-gradient(to bottom,rgb(39, 39, 39), rgb(29, 29, 29));
    .columns {
        margin-top: 30px;
        width: 80%;
        background-color:rgb(68, 68, 68);
        display: flex;
        flex-direction: row;
        .column {
            padding: 10px 30px;
            flex: 1;
            display: flex;
            color: rgb(107, 197, 219);
            font-size: 16px;
        }

    }
    .credits {
        width: 80%;

        display: flex;
        flex-direction: column;
        .credit {
            display: flex;
            flex: 1;
            padding: 10px 30px;
            background-color:rgb(51, 51, 51);
            border-bottom: 1px solid rgba(177, 239, 255, 0.281);
            .creditCatergory {
                flex: 1;
                display: flex;
                color: white;
                font-size: 16px;
            }
        }

    }
}