.container {
  display: flex;


  .description {

    padding: 20px;
    padding-top:10px;
    color: white;
    font-size: 17px;
  }
  .link {
    text-decoration: underline;
    font-size: 14px;
    margin-left: 10px;
    margin-top: -10px;
    color: #56c7d8;
}

}