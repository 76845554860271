.parent {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.step {
    height: 300px;
    width: 200px;
    background: rgba(58, 58, 58, 1);
    border-radius: 15px;
    margin: 0 20px;
    position: relative;
    overflow:hidden;

    .description {

      padding: 20px;
      padding-top:10px;
      color: white;
      font-size: 15px;
    }
    .link {
      text-decoration: underline;
      font-size: 14px;
      margin-left: 10px;
      margin-top: -10px;
      color: #56c7d8;
  }
  .illustrationContainer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 180px;
    border-radius: 10px 10px 0px 0px;
    background-image: linear-gradient(to right, var(--tw-gradient-stops));
    --tw-gradient-from: #42c3d3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, hsla(154, 94%, 40%, 0));
    --tw-gradient-to: #6eccdf;
    --tw-text-opacity: 1;
    --tw-shadow: 10px 10px 20px rgba(0, 0, 0, .25);
    --tw-shadow-colored: 10px 10px 20px var(--tw-shadow-color);
    .stepImg {

      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
  }