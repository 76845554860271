@keyframes pop {
    0% {
      transform: scale(1)
    }
  
    50% {
        transform: scale(1.2)
    }
    100% {
        transform: scale(1)
    }
  }
.logoImg {
    height: 220px;
    width: 220px;
    animation-name: pop;
    animation-duration: 1s;
    animation-delay: 0s;
    animation-iteration-count: infinite;
    pointer-events: none;

}