@font-face {
  font-family: "Roboto";
  src: local("Roboto"),
   url("./assets/fonts/Roboto-Medium.ttf") format("truetype");
  }
  @font-face {
    font-family: "OpenSans";
    src: local("OpenSans"),
     url("./assets/fonts/OpenSans-Regular.ttf") format("truetype");
    }
    @font-face {
      font-family: "OpenSansBold";
      src: local("OpenSansBold"),
       url("./assets/fonts/OpenSans-Bold.ttf") format("truetype");
      }
body {
  margin: 0;
  height: 100%;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: OpenSans;
}
#root {
  height: 100%;
  width: 100%;
}
html {
  margin: 0;
  height: 100%;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
