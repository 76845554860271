@keyframes popgif {
    0% {
      transform: scale(0) ;
    }
  
    50% {
        transform: scale(1.05) ;
    }
    100% {
        transform: scale(1) ;
    }
  }
.gifRowsRight {
    position: absolute;
    right: 0px;
    top: 70px;
    overflow:hidden;
    width: 30vw;
 }
  .gifRowsLeft {
    position: absolute;
    left: 0px;
    top: 244px;
    overflow:hidden;
  }
  .gifRowRight {
    display: flex;
    justify-content: flex-end;
    margin-right: -100px;
    margin-top: 0px;
    &:nth-child(odd) {
        transform: translateX(-75px);
    }

}
.gifRowLeft {
    display: flex;
    justify-content: flex-start;
    margin-left: -107px;
    &:nth-child(odd) {
        transform: translateX(75px);
    }
}
.gif {
    animation-name: popgif;
    animation-duration: 0.5s;
    animation-delay: 0.2s;
    animation-fill-mode: forwards;  
    cursor: pointer;
    margin-top: 0px;
    margin-right: 0;
    margin-bottom: 20px;
    margin-left: 20px;
    border-radius: 40px;
    width: 8vw;
    height:  8vw;
    transform: scale(0);
    transition: all 0.2s linear;
}
@media only screen and (max-width: 720px) {
    .gifRowsRight {
      display:none;
    }
    .gifRowsLeft{
        display:none;
      }
  }
